import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// dashboards
const DashBoard1 = React.lazy(() => import('../pages/dashboards/DashBoard1'));

// extra pages
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));

const Theme = React.lazy(() => import('../pages/forms/Tema'));
const Password = React.lazy(() => import('../pages/forms/ChangePassword'));
const UserList = React.lazy(() => import('../pages/user/User'));
const UserForm = React.lazy(() => import('../pages/user/UserForm'));
const AxisList = React.lazy(() => import('../pages/axis/Axis'));
const AxisForm = React.lazy(() => import('../pages/axis/AxisForm'));
const ProductList = React.lazy(() => import('../pages/product/Product'));
const ProductForm = React.lazy(() => import('../pages/product/ProductForm'));
const HelpPage = React.lazy(() => import ('../pages/help'))

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        layoutCls = VerticalLayout;

        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'auth',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={Error404} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={Error500} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin; editor'} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    element: <LoadComponent component={DashBoard1} />,
                },
                {
                    path: 'help',
                    element: <LoadComponent component={HelpPage} />
                },
                {
                    path: 'axis',
                    children: [
                        {
                            path: 'list',
                            element: <LoadComponent component={AxisList} />,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={AxisForm} />,
                        },
                    ],
                },
                {
                    path: 'products',
                    children: [
                        {
                            path: 'list',
                            element: <LoadComponent component={ProductList} />,
                        },
                        {
                            path: ':id',
                            element: <LoadComponent component={ProductForm} />,
                        },
                    ],
                },

                {
                    path: 'gestao',
                    children: [
                        {
                            path: 'tema',
                            element: <LoadComponent component={Theme} />,
                        },
                        {
                            path: 'senha',
                            element: <LoadComponent component={Password} />,
                        },
                        {
                            path: 'users',
                            element: <LoadComponent component={UserList} />,
                        },
                        {
                            path: 'users/:id',
                            element: <LoadComponent component={UserForm} />,
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
