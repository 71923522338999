function safelyParseJSON (json: any) {

    let parsed
  
    try {
      parsed = JSON.parse(json)
    } catch (e) {
      console.log('error on parse')
      parsed = json
    }
  
    return parsed // Could be undefined!
  }
  export {safelyParseJSON}